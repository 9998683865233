//import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import BTable from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowDown, HiOutlineArrowUp } from 'react-icons/hi';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { DynamicStyleClass, Show, isAbortError } from '~/app/shared';
import IconWrapper from '~/components/IconWrapper';
import { Paginated } from '../fetch/paginated.model';
import { TableCell } from './TableCell';
import { TableCellAction } from './TableCellAction';
import { TableAction } from './table-action.enum';
import './table.css';

type ColumnSortState = {
  sortId: string;
  isAsc: boolean;
};

type ExtendedColumn = {
  hidden?: boolean;
  Cell?: (props) => JSX.Element;
  defaultSort?: boolean;
  defaultSortAsc?: boolean;
} & Column;

interface TableProps {
  columns: ExtendedColumn[];
  fetchData: (
    abortController: AbortController,
    pageIndex: number,
    statePageSize: number,
    sortId?: number,
    isDesc?: boolean
  ) => Promise<Paginated<unknown>>;
  enablePagination?: boolean;
  pageSize?: number;
  refresh?: number;
  className?: string;
  checkCRMHighlight?: boolean;
  checkDataWarn?: boolean;
  highlightedColumnId?: string;
  highlightedDataWarnColumnId?: string;
  actions?: {
    [key in TableAction]?: (values) => void;
  };
}

export const Table = forwardRef<HTMLDivElement, TableProps>((props, ref) => {
  const {
    columns,
    className,
    checkCRMHighlight,
    highlightedColumnId,
    checkDataWarn,
    highlightedDataWarnColumnId,
    fetchData,
    pageSize = /* AVOID PAGINATION WITH ASTRONOMICAL PAGE SIZE AS DEFAULT, USEFULL IN SOME SCENARIOS */ 1000,
    actions,
    refresh = false,
    enablePagination = true
  } = props;

  const requestCounter = useRef<number>(0);
  const abortController = useRef<AbortController>(new AbortController());
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [columnSortState, setColumnSortState] = useState<ColumnSortState>();
  const [processedColumns, setProcessedColumns] = useState(columns);
  const [t] = useTranslation();
  const actionsTitle = useMemo(() => t('table.actions'), [t]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize: statePageSize }
  } = useTable(
    {
      columns: processedColumns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize,
        hiddenColumns: processedColumns.filter((x) => x.hidden).map((x) => x.accessor.toString()),
        sortBy: processedColumns
          .filter((x) => x.defaultSort)
          .map((x) => {
            return {
              desc: x.defaultSortAsc,
              id: x.accessor.toString()
            };
          })
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount: controlledPageCount
    },
    useSortBy,
    usePagination
  );

  useEffect(function onUnmount() {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  const updateInfo = useCallback(
    async (pageIndex, pageSize, sortId?, isAsc?) => {
      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      setIsLoading(true);

      try {
        const newData = await fetchData(abortController.current, pageIndex, statePageSize, sortId, isAsc);

        requestCounter.current += 1;

        if (newData) {
          setData(newData.items);
          setControlledPageCount(Math.ceil(newData.total / pageSize));
        }
      } catch (error) {
        if (!isAbortError(error)) {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [fetchData, statePageSize]
  );

  const onClickColumnSort = useCallback((column) => {
    if (!column.canSort) return;
    const columnSort = !column.isSortedDesc;
    column.toggleSortBy(columnSort);
    setColumnSortState({
      sortId: column.id,
      isAsc: columnSort
    });
  }, []);

  useEffect(() => {
    if (columnSortState) {
      updateInfo(pageIndex, statePageSize, columnSortState.sortId, columnSortState.isAsc);
    } else {
      updateInfo(pageIndex, statePageSize);
    }
    // On this case we want only refresh this on pagination change, we can find a better strategy on the future
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, statePageSize, columnSortState, updateInfo]);

  useEffect(() => {
    function addActionsToColumns() {
      if (!actions) {
        return;
      }

      setProcessedColumns([
        ...columns.map((column) => {
          return {
            ...column,
            Cell: column.Cell ? column.Cell : (props) => <TableCell {...props} />
          };
        }),
        {
          Header: actionsTitle,
          accessor: 'actions',
          width: Object.keys(actions).length * 50,
          Cell: ({ cell }) => <TableCellAction actions={actions} values={cell?.row?.values} />,
          disableSortBy: true
        }
      ]);
    }

    addActionsToColumns();
  }, [actions, actionsTitle, columns]);

  useEffect(() => {
    if (!refresh || !requestCounter.current) return;
    if (columnSortState) {
      updateInfo(0, statePageSize, columnSortState.sortId, columnSortState.isAsc);
    } else {
      updateInfo(0, statePageSize);
    }
    gotoPage(0);
  }, [gotoPage, refresh, statePageSize, columnSortState, updateInfo]);

  if (isLoading) {
    return (
      <div
        style={{
          height: '300px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div>
          <div style={{ display: 'grid' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      </div>
    );
  }

  const getColumnById = (row, referenceId) => {
    return row.allCells.find((c) => c.column.id === referenceId).column;
  };

  const getDataWarnHighlighted = (row) => {
    if (isLoading) {
      return false;
    }

    const highlightedRow = getColumnById(row, highlightedDataWarnColumnId);

    if (!highlightedRow) {
      return false;
    }

    return highlightedRow.isDataWarnHighlighted(row);
  };

  const getCrmHighlited = (row) => {
    if (isLoading) {
      return false;
    }

    const CRMColumn = getColumnById(row, highlightedColumnId);

    if (!CRMColumn) {
      return false;
    }

    return CRMColumn.isCrmHighlighted(row);
  };

  return (
    <div ref={ref} className={className ?? 'custom-table'}>
      <BTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <th
                  key={j}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ width: column.width }}
                  className={`${column['headerClassName'] || ''}`}
                  onClick={() => onClickColumnSort(column)}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.6rem' }}>
                    {column.render('Header')}

                    <span>
                      <Show
                        when={column.isSorted}
                        fallback={
                          <Show when={!column.disableSortBy}>
                            <IconWrapper Icon={HiOutlineArrowDown} className="custom-table__sort-icon" />
                          </Show>
                        }
                      >
                        <Show
                          when={column.isSortedDesc}
                          fallback={
                            <IconWrapper
                              Icon={HiOutlineArrowDown}
                              className="custom-table__sort-icon custom-table__sort-icon--active"
                            />
                          }
                        >
                          <IconWrapper
                            Icon={HiOutlineArrowUp}
                            className="custom-table__sort-icon custom-table__sort-icon--active"
                          />
                        </Show>
                      </Show>
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <tr
                key={i}
                {...row.getRowProps()}
                data-warn={checkDataWarn ? getDataWarnHighlighted(row) : false}
                className={new DynamicStyleClass()
                  .add('custom-table__cell--won', checkCRMHighlight === true && getCrmHighlited(row))
                  .toString()}
              >
                {row.cells.map((cell, j) => (
                  <td
                    style={{ maxWidth: cell.column.width }}
                    key={j}
                    {...cell.getCellProps()}
                    className={`${cell.column['className'] || ''}`}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </BTable>

      <Container fluid className="custom-table__footer">
        {controlledPageCount > 1 && page.length > 0 && enablePagination && (
          <Row>
            <Col></Col>
            <Pagination className="custom-table__pagination col">
              <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage || isLoading} />
              <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage || isLoading} />
              <Pagination.Item onClick={() => gotoPage(pageIndex - 1)} disabled={!canPreviousPage || isLoading}>
                {canPreviousPage ? pageIndex : '-'}
              </Pagination.Item>
              <Pagination.Item active onClick={() => gotoPage(pageIndex)} disabled={isLoading}>
                {isLoading && page?.length > 0 ? (
                  <Spinner data-testid="spinner" animation="border" size="sm" variant="primary" />
                ) : (
                  pageIndex + 1
                )}
              </Pagination.Item>

              <Pagination.Item onClick={() => gotoPage(pageIndex + 1)} disabled={!canNextPage || isLoading}>
                {canNextPage ? pageIndex + 2 : '-'}
              </Pagination.Item>

              <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage || isLoading} />
              <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage || isLoading} />
            </Pagination>
            <Col className="custom-table__showing">
              {pageIndex + 1}/{controlledPageCount}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
});
