export type ParentAccount = {
  id: number;
  crmId: string;
  name: string;
  category?: AccountCategory;
  directorId?: number;
  clientManagerId: number;
};

export enum AccountCategory {
  PreferredCustomer,
  ManagedCustomer,
  Standard
}
