import { lazy } from 'react';
import { Route } from 'react-router-dom';

const CustomUnits = lazy(() => import('./list/CustomUnitsList'));

export const customUnitRoutes = (
  <>
    <Route path="/custom-units" element={<CustomUnits />} />
  </>
);
