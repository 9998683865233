// This is the same than AuthSubject in the API (src\PlainConcepts.CapacityTool.Domain\Auth\Enums\AuthSubject.cs)

export enum AuthSubject {
  None,
  ///Represents any Subject
  All,
  ///Teams
  Team,
  ///Employees
  EmployeeList,
  Employee,
  EmployeeExternalList,
  EmployeeExternal,
  EmployeeRole,
  EmployeeType,
  ///Proposals
  ProposalList,
  Proposal,
  ProposalMadeOnly,
  ProposalOwnedOnly,
  ProposalOwner,
  ///Projects
  ProjectList,
  Project,
  ProjectOwnedOnly,
  ProjectOwner,
  ProjectPlain,
  ProjectFinancial,
  ///Products
  ProductList,
  Product,
  ProductPlain,
  ///Forecasts
  AllForecastUnits,
  AllForecastOwnedUnitOnly,
  Forecast,
  ForecastFromPast,
  ///Billings
  Billing,
  BillingIntercompanyReport,
  ///BillingUnit
  BillingUnit,
  BilligUnitReopener,
  ///Reports
  ActiveProposalsReport,
  BonificationsReport,
  EffortReport,
  GlobalAccumulatedReport,
  GlobalAvailableCapacityReport,
  GlobalBillableReport,
  OvercapacityReport,
  ///Units
  MyUnitsList,
  CustomUnit
}
