import { useDrag } from '@use-gesture/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineShieldCheck } from 'react-icons/hi';
import {
  TbBuildingCommunity,
  TbBuildings,
  TbCertificate,
  TbChartDots,
  TbChartPie4,
  TbChevronRight,
  TbFileInvoice,
  TbId,
  TbMenu2,
  TbNotebook,
  TbNotes,
  TbReceipt,
  TbStack2,
  TbTent
} from 'react-icons/tb';
import { NavLink, useLocation } from 'react-router-dom';
import { Gesture } from '~/app/core';
import IconWrapper from '~/components/IconWrapper';
import { AuthAction, AuthSubject, Can, DynamicStyleClass, Initials, Show, Tooltip, useAuthStore } from '../shared';
import './nav-bar.css';

const PRODUCTS_LINK_NAME = 'products';
const NAV_LINKS_WITH_ABILITY = [
  {
    name: 'forecast',
    to: '/forecast',
    icon: TbNotes,
    subject: AuthSubject.Forecast
  },
  {
    name: 'employees',
    to: '/employees',
    icon: TbId,
    subject: AuthSubject.EmployeeList
  },
  {
    name: 'teams',
    to: '/teams',
    icon: TbTent,
    subject: AuthSubject.Team
  },
  {
    name: 'projects',
    to: '/projects',
    icon: TbStack2,
    subject: AuthSubject.ProjectList
  },
  {
    name: 'billings',
    to: '/billing',
    icon: TbReceipt,
    subject: AuthSubject.Billing
  },
  {
    name: 'billings-unit',
    to: '/unit-billing',
    icon: TbFileInvoice,
    subject: AuthSubject.BillingUnit
  },
  {
    name: 'effort-reports',
    to: '/effort-reports',
    icon: TbChartDots,
    subject: AuthSubject.EffortReport
  },
  {
    name: 'global-reports',
    to: '/global-reports',
    icon: TbChartPie4,
    subject: [
      AuthSubject.GlobalAccumulatedReport,
      AuthSubject.GlobalAvailableCapacityReport,
      AuthSubject.GlobalBillableReport
    ]
  },
  {
    name: 'proposals',
    to: '/proposals',
    icon: TbNotebook,
    subject: AuthSubject.ProposalList
  },
  {
    name: 'bonifications',
    to: '/bonifications',
    icon: TbCertificate,
    subject: AuthSubject.BonificationsReport
  },
  {
    name: 'my-units',
    to: '/my-units',
    icon: TbBuildings,
    subject: AuthSubject.MyUnitsList
  },
  {
    name: 'custom-units',
    to: '/custom-units',
    icon: TbBuildingCommunity,
    subject: AuthSubject.CustomUnit
  }
] as const;

interface NavIconProps {
  name: string;
  to: string;
  icon: any;
  onClick: () => void;
}

const NavIcon: React.FunctionComponent<NavIconProps> = ({ name, to, icon: NavLinkIcon, onClick }) => {
  const [t] = useTranslation();

  return (
    <Tooltip title={t(`nav.${name}`)} placement="right">
      <NavLink
        to={to}
        onClick={onClick}
        className={({ isActive }) =>
          new DynamicStyleClass()
            .fromRecord({
              'nav-bar__link': true,
              'nav-bar__link--semidarker': true,
              'nav-bar__is-active': isActive,
              'is-active': isActive,
              'allow-click': PRODUCTS_LINK_NAME === name
            })
            .toString()
        }
      >
        <NavLinkIcon className="nav-bar__icon" />
        <NavLinkIcon className="nav-bar__icon nav-bar__icon--solid" />

        <span className="nav-bar__name ">{t(`nav.${name}`)}</span>
      </NavLink>
    </Tooltip>
  );
};

const NavBar: React.FunctionComponent = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const [authState] = useAuthStore();

  const [isNavigationDrawerOpened, setNavigationDrawerOpened] = useState<boolean>(false);

  const onDrag: Gesture = useDrag(({ active, movement: [x] }) => {
    const MIN_SIZE = 30;
    if (!active && Math.abs(x) > MIN_SIZE) {
      setTimeout(() => setNavigationDrawerOpened(x > MIN_SIZE), 10);
    }
  });

  function toggleNavigationDrawer(): void {
    setNavigationDrawerOpened((prev) => !prev);
  }

  const isAuthRelatedRoute: boolean = ['/auth/login', '/auth/logout'].includes(location.pathname);

  return (
    <Show when={!isAuthRelatedRoute} fallback={<div></div>}>
      <aside
        className={new DynamicStyleClass()
          .fromRecord({
            'nav-bar': true,
            'is-open': isNavigationDrawerOpened
          })
          .toString()}
      >
        <div className="nav-bar__container" {...onDrag()}>
          <div className="nav-bar__user ">
            <Initials text={authState.name} roleName={authState.roleName} placement="right" size={26} />

            <Tooltip title={authState.name} placement="right">
              <span className="nav-bar__name">{authState.name}</span>
            </Tooltip>

            <Can I={AuthAction.Manage} a={AuthSubject.All}>
              <Tooltip title={t('roles.administrator')} placement="right">
                <IconWrapper Icon={HiOutlineShieldCheck} className="nav-bar__icon nav-bar__icon--admin" />
              </Tooltip>
            </Can>
          </div>

          {authState.maintenance ? (
            <></>
          ) : (
            NAV_LINKS_WITH_ABILITY.map((link) => (
              <Can key={link.name} I={AuthAction.Read} a={link.subject}>
                <NavIcon
                  name={link.name}
                  to={link.to}
                  icon={link.icon}
                  onClick={() => setNavigationDrawerOpened(false)}
                />
              </Can>
            ))
          )}
        </div>

        <IconWrapper Icon={TbChevronRight} className="nav-bar__chevron" onClick={toggleNavigationDrawer} />
        <IconWrapper Icon={TbMenu2} className="nav-bar__hamburger" onClick={toggleNavigationDrawer} />
      </aside>
    </Show>
  );
};

export default NavBar;
