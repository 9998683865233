import { IconType } from 'react-icons';
import './icon-wrapper.css';

interface IconWrapperProps {
  Icon: IconType;
  size?: number;
  className?: string;
  onClick?: () => void;
}

const IconWrapper = ({ Icon, className, onClick, size = 25 }: IconWrapperProps) => {
  const interactiveClass = onClick ? 'icon-cursor' : '';
  return <Icon size={size} onClick={onClick} className={`${interactiveClass} ${className}`} />;
};

export default IconWrapper;
