import { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiOutlineSearch } from 'react-icons/hi';
import { isAbortError, toastService } from '~/app/shared';
import IconWrapper from '~/components/IconWrapper';
import './filter.css';

interface FilterProps {
  title?: string;
  defaultValue: string;
  placeholder: string;
  onChange: (filter: string) => any;
  customFilterCss?: any;
}

const Filter: React.FunctionComponent<FilterProps> = (props) => {
  const { title = '', defaultValue = '', placeholder = '', onChange, customFilterCss } = props;

  const [t] = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = useCallback(
    (data): void => {
      if (errors?.length) return;

      setIsSubmitting(true);

      try {
        onChange(data?.filter);
        reset({});
      } catch (error) {
        if (!isAbortError(error)) {
          toastService.error(error);
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [errors?.length, onChange, reset]
  );

  return (
    <Container fluid className="mb-3 p-0 filter">
      <Row className="mb-2 p-0">
        <Col xs="12">{title}</Col>
      </Row>

      <Row className="p-0">
        <Col xs="12">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="p-0">
              <Col xs="10" md={`${customFilterCss ? customFilterCss : '4'}`}>
                <Form.Group className="mb-3" controlId="filter">
                  <Form.Control
                    {...register('filter', {
                      maxLength: { value: 50, message: t('form.errors.maxLength') }
                    })}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                  />
                  <Form.Control.Feedback type="invalid">{errors.filter?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="2">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  <IconWrapper Icon={HiOutlineSearch} className="filter__icon" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Filter;
