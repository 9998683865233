import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { HiOutlineChat, HiOutlineEye, HiOutlineEyeOff, HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi';
import { Tooltip } from '../tooltip';
import { TableAction } from './table-action.enum';

type TableCellActionProps = {
  actions: {
    [key in TableAction]?: (values) => void;
  };
  values: unknown;
};

export function TableCellAction(props: TableCellActionProps) {
  const { actions, values } = props;
  const [t] = useTranslation();

  const icons = {
    [TableAction.Edit]: HiOutlinePencil,
    [TableAction.Delete]: HiOutlineTrash,
    [TableAction.ToggleActive]: (values as any)?.isActive ? HiOutlineEyeOff : HiOutlineEye,
    [TableAction.MSTeams]: HiOutlineChat
  };

  const ActionButton = ({ name, action }) => {
    function onClickButton() {
      action(values);
    }

    const Icon = icons[name];

    return (
      <Tooltip title={t(`form.actions.${name}`)}>
        <Button variant="light" className="shadow-none custom-table__action" onClick={onClickButton}>
          <Icon className="custom-table__icon" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <span>
      {Object.entries(actions).map(([key, value], i) => (
        <ActionButton key={i} name={key} action={value} />
      ))}
    </span>
  );
}
