import { TFunction } from 'i18next';
import { TbAlignBoxLeftMiddle, TbSquareAsterisk, TbSquareOff } from 'react-icons/tb';
import { t } from '~/app/app.i18n';
import { ForecastCell } from '~/app/forecast/shared';
import { getSpecialEffortNameKey } from '~/app/forecast/shared/helpers/special-effort.helper';
import { ReactComponent as SquareOffS } from '~/assets/icons/SquareOffS.svg';
import { ReactComponent as PlainLogo } from '~/assets/images/LogoPlainForecast.svg';
import { COLOR_ALPHA_TAGS, isBillable, ProductStatus, randomColor, rgba2hex } from '..';
import { ColorModel } from '../color/color.model';
import { ProductType } from './product-type.enum';
import { Product } from './product.model';

export function longProductName(product: Product): string {
  return `${product.projectName} - ${product.name} - ${product.clientName}`;
}

export enum ColorConstants {
  EmptyColor = '{}',
  DefaultForeground = '#000000',
  DefaultBackground = '#FFFFFF'
}

export enum BulletStyle {
  Normal = 'normal',
  Special = 'special',
  Internal = 'internal',
  NonBillable = 'non-billable',
  Plain = 'Plain',
  NonBillableSales = 'non-billable-by-sales'
}

type ProductSelectOption = { label: string; value: Product; disabled: boolean };

export function productsToSelectOptions(products: Product[]): ProductSelectOption[] {
  return products.map((product) => ({
    value: product,
    label: longProductName(product),
    disabled: `${product.status}` === ProductStatus.Finished
  }));
}

function getBulletStyles(product: Product, color: { foreground: string; background: string }, nonBillable = false) {
  const styleStrategies = {
    [BulletStyle.Special]: {
      check() {
        return product?.isSpecialEffort;
      },
      execute() {
        return {
          background: color.background,
          foreground: color.foreground,
          image: 'none',
          type: BulletStyle.Special,
          size: '5px 5px'
        };
      }
    },
    [BulletStyle.Plain]: {
      check() {
        return product?.type === ProductType.Plain;
      },
      execute() {
        return {
          background: color.foreground,
          foreground: color.background,
          image: 'none',
          type: BulletStyle.Plain,
          size: '10px 10px'
        };
      }
    },
    [BulletStyle.Internal]: {
      check() {
        return product?.type === ProductType.Internal;
      },
      execute() {
        return {
          background: color.foreground,
          foreground: color.background,
          image: 'none',
          type: BulletStyle.Internal,
          size: '10px 10px'
        };
      }
    },
    [BulletStyle.NonBillable]: {
      check() {
        return nonBillable;
      },
      execute() {
        return {
          background: color.foreground,
          foreground: color.foreground,
          image: 'none',
          type: BulletStyle.NonBillable,
          size: '0'
        };
      }
    },
    [BulletStyle.NonBillableSales]: {
      check() {
        return nonBillable;
      },
      execute() {
        return {
          background: color.foreground,
          foreground: color.foreground,
          image: 'none',
          type: BulletStyle.NonBillableSales,
          size: '0'
        };
      }
    },
    [BulletStyle.Normal]: {
      check() {
        return true;
      },
      execute() {
        return {
          background: color.foreground,
          foreground: color.foreground,
          image: 'none',
          type: BulletStyle.Normal,
          size: '0'
        };
      }
    }
  };

  const styleStrategy = Object.values(styleStrategies).find((x) => x.check());
  const style = styleStrategy.execute();

  const backgroundColor = style.background;

  color.background = backgroundColor;
  color.foreground = style.foreground;

  return {
    product,
    color,
    backgroundImage: style.image,
    backgroundColor,
    backgroundSize: style.size,
    productType: style.type
  } as const;
}

function getProductFromSpecialEffort(specialEffort: number, t?: TFunction): Product {
  const translator = t || ((key: string) => key);

  const nameKey = getSpecialEffortNameKey(specialEffort);

  return {
    id: specialEffort,
    name: translator(nameKey),
    isSpecialEffort: true,
    clientName: 'SPECIAL',
    scope: null,
    status: null,
    quoteId: null,
    type: null,
    color: ColorConstants.EmptyColor
  };
}
export function getProductCellBulletStyles(cell: ForecastCell) {
  const product = cell?.specialEffort ? getProductFromSpecialEffort(cell.specialEffort, t) : cell?.product;

  return getProductBulletStyles(product, !isBillable(cell?.billableType));
}

export function getRawProductBulletStyles(rawProduct: Product) {
  const product = rawProduct?.isSpecialEffort ? getProductFromSpecialEffort(rawProduct.id, t) : rawProduct;

  return getProductBulletStyles(product);
}

function getProductBulletStyles(product: Product, nonBillable = false) {
  let color: ColorModel;
  if (hasEmptyColorValue(product?.color)) {
    color = getColorModelFromLongProductName(product?.clientName, product?.name);
  } else {
    color = JSON.parse(product?.color);
  }

  return getBulletStyles(product, color, nonBillable);
}

export const hasEmptyColorValue = (color: any) =>
  color === '' || color === ColorConstants.EmptyColor || color === undefined || color === null;

export function getColorModelFromLongProductName(clientName: string, productName: string): ColorModel {
  const color = randomColor(`${clientName} - ${productName}`, COLOR_ALPHA_TAGS);

  const events = [
    {
      name: 'Idle',
      color: '#FFFAC9'
    },
    {
      name: 'Not available',
      color: '#E0E0E0'
    },
    {
      name: 'Other',
      color: '#D4F5FC'
    },
    {
      name: 'Sales support',
      color: '#E9FFFB'
    },
    {
      name: 'Sick or leave',
      color: '#FFE9FA'
    },
    {
      name: 'Vacation',
      color: '#F3ECFF'
    },
    {
      name: 'Bank holiday',
      color: '#DFEDFF'
    },
    {
      name: 'Default',
      color: '#FFFFFF'
    }
  ];

  function getColor(event: string): string {
    return events.filter((e) => e.name === event).map((item) => item.color)[0];
  }

  switch (productName) {
    case 'Idle': {
      const colorModel: ColorModel = {
        foreground: getColor('Idle'),
        background: getColor('Idle')
      };
      return colorModel;
    }
    case 'Not available': {
      const colorModel: ColorModel = {
        foreground: getColor('Not available'),
        background: getColor('Not available')
      };
      return colorModel;
    }
    case 'Other': {
      const colorModel: ColorModel = {
        foreground: getColor('Other'),
        background: getColor('Other')
      };
      return colorModel;
    }
    case 'Sales support': {
      const colorModel: ColorModel = {
        foreground: getColor('Sales support'),
        background: getColor('Sales support')
      };
      return colorModel;
    }
    case 'Sick or leave': {
      const colorModel: ColorModel = {
        foreground: getColor('Sick or leave'),
        background: getColor('Sick or leave')
      };
      return colorModel;
    }
    case 'Vacation': {
      const colorModel: ColorModel = {
        foreground: getColor('Vacation'),
        background: getColor('Vacation')
      };
      return colorModel;
    }
    case 'Bank holiday': {
      const colorModel: ColorModel = {
        foreground: getColor('Bank holiday'),
        background: getColor('Bank holiday')
      };
      return colorModel;
    }
    default: {
      const colorModel: ColorModel = {
        foreground: rgba2hex(color.foreground),
        background: '#ffffff'
      };
      return colorModel;
    }
  }
}

const productIcons = [
  {
    icon: TbAlignBoxLeftMiddle,
    bulletStyle: BulletStyle.Normal
  },
  {
    icon: TbSquareAsterisk,
    bulletStyle: BulletStyle.Internal
  },
  {
    icon: TbSquareOff,
    bulletStyle: BulletStyle.NonBillable
  },
  {
    icon: PlainLogo,
    bulletStyle: BulletStyle.Plain
  },
  {
    icon: SquareOffS,
    bulletStyle: BulletStyle.NonBillableSales
  }
];

export const getProductIconByBulletStyle = (bulletStyle: BulletStyle) =>
  productIcons.find((e) => e.bulletStyle === bulletStyle).icon;
